<template>
  <table class="table table-sm table-bordered table-sm">
    <thead>
      <tr>
        <th>Ticketnummer</th>
        <th>Kundennummer</th>
        <th>Name</th>
        <th width="1%">Postleitzahl</th>
        <th width="1%">Ort</th>
        <th>Benutzer</th>
        <th class="text-center">Vorlauf</th>
        <th class="text-center">Anlieferung</th>
        <th class="text-center">Abholung</th>
        <th class="text-center">Nachlauf</th>

        <th class="text-center">Erstellt am</th>
        <th class="text-center" v-if="role === 'admin'" width="1%">
          Reservierung übernehmen
        </th>
        <th class="text-center" width="1%">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="booking in bookings" :key="booking._id">
        <td class="text-center">{{ booking.ticketNr }}</td>
        <td>{{ booking.customer.customerId }}</td>
        <td>{{ booking.customer.name }}</td>
        <td width="1%">{{ booking.customer.zipcode }}</td>
        <td width="1%">{{ booking.customer.city }}</td>
        <td width="10%">
          {{ booking.createdBy }}
        </td>
        <td class="text-center" width="1%">
          {{ booking.start | date('dd.MM.yyyy') }}
        </td>
        <td class="text-center" width="1%">
          {{ booking.originalStart | date('dd.MM.yyyy') }}
        </td>
        <td class="text-center" width="1%">
          {{ booking.originalEnd | date('dd.MM.yyyy') }}
        </td>
        <td class="text-center" width="1%">
          {{ booking.end | date('dd.MM.yyyy') }}
        </td>
        <td class="text-center" width="10%">
          {{ booking.createdAt | date('dd.MM.yyyy') }}
        </td>
        <th class="text-center" width="1%" v-if="role === 'admin'">
          <button type="button" class="btn btn-sm btn-success btn-block" :disabled="requesting"
            @click="onCreate(booking)">
            <i class="fa fa-fw fa-save"></i>
          </button>
        </th>
        <td>
          <button type="button" class="btn btn-sm btn-danger btn-block" :disabled="requesting ||
            (booking.createdBy !== currentUser && role !== 'admin')
            " @click="onDelete(booking._id)">
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'reservations-table',
  props: {
    bookings: {
      type: Array,
      required: true,
    },
    requesting: {
      type: Boolean,
      required: true,
    },
    role: {
      type: String,
      default: 'guest',
    },
    currentUser: {
      type: String,
      default: 'anonymous',
    },
  },
  methods: {
    onDelete(id) {
      this.$emit('deleteBooking', id);
    },
    onCreate(booking) {
      this.$emit('createReservation', booking);
    },
  },
};
</script>
