<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <div class="input-group input-group-sm mb-3">
        <input type="text" class="form-control form-control-sm" v-model.trim="customerQuery"
          placeholder="Kundennummer suchen..." />
        <div class="input-group-append">
          <div class="input-group-text">
            <i class="fa fa-fw fa-search"></i>
          </div>
        </div>
      </div>
      <select v-model="selectedCustomer" class="form-control form-control-sm" required>
        <option :value="null" disabled>Kunde auswählen...</option>
        <option :value="selectedCustomer" :key="selectedCustomer._id" v-for="selectedCustomer in filteredCustomers">
          {{ selectedCustomer.name }}
        </option>
      </select>
    </div>
    <div class="form-group" v-if="selectedCustomer">
      <div class="form-group">
        <strong>Kundeninfo</strong>
        <div>
          <input type="checkbox" id="is-pharma" v-model="isPharma" />
          <label for="is-pharma"> Pharma</label>
        </div>
      </div>
      <div class="form-group">
        <label for="customerId">Kundennummer</label>
        <input type="text" class="form-control form-control-sm" :value="selectedCustomer.customerId" disabled />
      </div>
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control form-control-sm" :value="selectedCustomer.name" disabled />
      </div>
      <div class="form-group">
        <label for="zipcode">Postleitzahl</label>
        <input type="text" class="form-control form-control-sm" :value="selectedCustomer.zipcode" disabled />
      </div>
      <div class="form-group">
        <label for="city">Stadt</label>
        <input type="text" class="form-control form-control-sm" :value="selectedCustomer.city" disabled />
      </div>
    </div>
    <div class="form-group" v-if="selectedCustomer">
      <div class="form-group">
        <strong>Typen</strong>
      </div>
      <div class="form-check" v-for="weight in weights" :key="weight._id">
        <input class="form-check-input" type="checkbox" :value="weight" :id="weight._id" v-model="selectedWeightTypes" />
        <label class="form-check-label" :for="weight._id">
          {{ weight.type }}
        </label>
      </div>
    </div>
    <div class="form-row" v-if="selectedWeightTypes.length">
      <div class="col-6">
        <div class="form-group">
          <label for="start"><strong>Von</strong></label>
          <input type="date" class="form-control form-control-sm" required v-model="selectedStart" id="start" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="end"><strong>Bis</strong></label>
          <input type="date" class="form-control form-control-sm" required v-model="selectedEnd" id="end" />
        </div>
      </div>
    </div>
    <table class="table table-sm" v-if="filteredWeights.length">
      <thead>
        <tr>
          <th></th>
          <th>Typ</th>
          <th class="text-center">Anzahl</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="weight in filteredWeights" :key="weight.type">
          <td></td>
          <td>{{ weight.type }}</td>
          <td class="text-center">
            <input type="number" min="0" :max="weight.amount" v-model.number="selectedWeightTypes.find((w) => w.type === weight.type)
              .selectedAmount
              " />
            /
            {{ weight.amount }}
          </td>
        </tr>
      </tbody>
    </table>
    <button type="submit" class="btn btn-sm btn-block btn-success" :disabled="!selectedWeightTypes.length">
      Reservieren
    </button>
    <div class="alert alert-danger mt-3 text-center" v-if="errMsg">
      {{ errMsg }}
    </div>
  </form>
</template>

<script>
import {
  addDays,
  format,
  getTime,
  isWeekend,
  parseISO,
  subDays,
} from 'date-fns';

import {
  createReservation,
  getAvailableWeights,
  getCustomers,
  getWeights,
} from '../../api';

export default {
  name: 'create-reservation-form',
  async created() {
    this.pollQuery();
    const [weights, customers] = await Promise.all([
      getWeights(),
      getCustomers(),
    ]);

    this.weights = weights;
    this.customers = customers;
  },
  data() {
    return {
      errMsg: '',
      weights: [],
      customers: [],
      isPharma: false,
      customerQuery: '',
      availableWeights: [],
      selectedCustomer: null,
      selectedWeightTypes: [],
      isEdelstahl3000: false,
      selectedStart: format(new Date(), 'yyyy-MM-dd'),
      selectedEnd: format(new Date(), 'yyyy-MM-dd'),
      pollQuerying: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.pollQuerying);
  },
  methods: {
    alignDateQuery() {
      // Sub 1 Day Vorlauf everytime
      let start = getTime(subDays(parseISO(this.selectedStart), 1));
      let end = getTime(parseISO(this.selectedEnd));

      while (isWeekend(start)) {
        start = getTime(subDays(new Date(start), 1));
      }

      while (isWeekend(end)) {
        end = getTime(addDays(new Date(end), 1));
      }

      if (this.isPharma) {
        start = getTime(subDays(new Date(start), 1));

        while (isWeekend(start)) {
          start = getTime(subDays(new Date(start), 1));
        }
      }

      if (
        this.selectedWeightTypes.some((w) => {
          return (
            w.type !== 'Edelstahl 1500' ||
            (w.type === 'Edelstahl 1500' && w.selectedAmount >= 2)
          );
        })
      ) {
        end = getTime(addDays(new Date(end), 1));

        while (isWeekend(end)) {
          end = getTime(addDays(new Date(end), 1));
        }
      }

      return {
        start,
        end,
      };
    },
    pollQuery() {
      this.pollQuerying = setInterval(() => {
        if (
          this.selectedWeightTypes.some((w) => {
            return w.type === 'Edelstahl 1500' && w.selectedAmount >= 2;
          })
        ) {
          this.isEdelstahl3000 = true;
        } else {
          this.isEdelstahl3000 = false;
        }
      }, 1000);
    },
    async onSubmit() {
      this.selectedWeightTypes.forEach((w) => {
        if (w.amount < w.selectedAmount) {
          this.errMsg = 'Diese Reservierung ist nicht möglich';

          return;
        }
      });

      let reservationWeights;

      reservationWeights = this.selectedWeightTypes.map((weight) => {
        const { selectedAmount, _id, ...rest } = weight;

        rest.amount = selectedAmount;

        return rest;
      });

      delete this.selectedCustomer._id;

      try {
        const result = this.alignDateQuery();

        const booking = await createReservation({
          weights: reservationWeights,
          start: result.start,
          end: result.end,
          originalStart: getTime(parseISO(this.selectedStart)),
          originalEnd: getTime(parseISO(this.selectedEnd)),
          customer: this.selectedCustomer,
        });

        this.isPharma = false;
        this.isEdelstahl3000 = false;
        this.selectedWeightTypes = [];
        this.selectedCustomer = null;
        this.selectedStart = format(new Date(), 'yyyy-MM-dd');
        this.selectedEnd = format(new Date(), 'yyyy-MM-dd');
        this.$emit('reservationCreated', booking);
      } catch (e) {
        console.error(e);

        if (e.message.includes('400')) {
          this.errMsg = 'Diese Reservierung ist nicht möglich';
        } else {
          this.errMsg = e.message;
        }
      }
    },
  },
  watch: {
    async selectedStart() {
      this.availableWeights = await getAvailableWeights(this.alignDateQuery());
    },
    async selectedEnd() {
      this.availableWeights = await getAvailableWeights(this.alignDateQuery());
    },
    selectedWeightTypes: {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        newValue.forEach((selectedWeightType) => {
          if (!selectedWeightType.selectedAmount) {
            selectedWeightType.selectedAmount = 0;
          }
        });

        this.availableWeights = await getAvailableWeights(
          this.alignDateQuery()
        );
      },
    },
    async isPharma() {
      this.availableWeights = await getAvailableWeights(this.alignDateQuery());
    },
    async isEdelstahl3000() {
      this.availableWeights = await getAvailableWeights(this.alignDateQuery());
    },
  },
  computed: {
    filteredCustomers() {
      if (!this.customerQuery) {
        return this.customers;
      }

      return this.customers.filter((customer) => {
        return customer.customerId.startsWith(this.customerQuery);
      });
    },
    // alignDateQuery() {
    //   // Sub 1 Day Vorlauf everytime
    //   let start = getTime(subDays(parseISO(this.selectedStart), 1));
    //   let end = getTime(parseISO(this.selectedEnd));

    //   while (isWeekend(start)) {
    //     start = getTime(subDays(new Date(start), 1));
    //   }

    //   while (isWeekend(end)) {
    //     end = getTime(addDays(new Date(end), 1));
    //   }

    //   if (this.isPharma) {
    //     start = getTime(subDays(new Date(start), 1));

    //     while (isWeekend(start)) {
    //       start = getTime(subDays(new Date(start), 1));
    //     }
    //   }

    //   if (
    //     this.selectedWeightTypes.some((w) => {
    //       return (
    //         w.type !== 'Edelstahl 1500' ||
    //         (w.type === 'Edelstahl 1500' && w.selectedAmount >= 2)
    //       );
    //     })
    //   ) {
    //     end = getTime(addDays(new Date(end), 1));

    //     while (isWeekend(end)) {
    //       end = getTime(addDays(new Date(end), 1));
    //     }
    //   }

    //   return {
    //     start,
    //     end,
    //   };
    // },
    filteredWeights() {
      const filterW = this.availableWeights.filter((availableWeight) => {
        return this.selectedWeightTypes.find(
          (weight) => weight.type === availableWeight.type
        );
      });

      return filterW.map((filteredWeight) => {
        filteredWeight.amount =
          this.weights.find((weight) => weight.type === filteredWeight.type)
            .amount - filteredWeight.amount;
        return filteredWeight;
      });
    },
  },
};
</script>
