<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-7">
          <h3>
            Reservierungen
            <small class="text-muted">{{ bookingsCount }}</small>
          </h3>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 col-xl-2">
          <div class="form-group">
            <input
              type="text"
              v-model="query.ticketNr"
              class="form-control form-control-sm"
              placeholder="Ticketnummer suchen..."
            />
          </div>
        </div>
        <div class="col-12 col-xl-2">
          <div class="form-group">
            <input
              type="text"
              v-model="query.customerId"
              class="form-control form-control-sm"
              placeholder="Kundennummer suchen..."
            />
          </div>
        </div>
        <div class="col-12 col-xl-2">
          <div class="form-group">
            <input
              type="text"
              v-model="query.name"
              class="form-control form-control-sm"
              placeholder="Name suchen..."
            />
          </div>
        </div>
        <div class="col-12 col-xl-2">
          <div class="form-group">
            <input
              type="text"
              v-model="query.zipcode"
              class="form-control form-control-sm"
              placeholder="Postleitzahl suchen..."
            />
          </div>
        </div>
        <div class="col-12 col-xl-2">
          <div class="form-group">
            <input
              type="text"
              v-model="query.city"
              class="form-control form-control-sm"
              placeholder="Ort suchen..."
            />
          </div>
        </div>
        <div class="col-12 col-xl-2">
          <div class="form-group">
            <input
              type="text"
              v-model="query.createdBy"
              class="form-control form-control-sm"
              placeholder="Benutzer suchen..."
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-9">
          <bookings-table
            :bookings="bookings"
            :requesting="requesting"
            @deleteBooking="onDeleteBooking"
            @createReservation="onCreateReservation"
            :role="role"
            :currentUser="currentUser"
          ></bookings-table>
        </div>
        <div class="col-12 col-xl-3">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-center text-danger">
                    Vorlaufzeit wird automatisch berücksichtigt
                  </h5>
                  <div class="card-text">
                    <div class="row">
                      <div class="col">
                        <strong>Edelstahl 1500:</strong>
                      </div>
                      <div class="col">1 Tag Vorlauf</div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <strong>Edelstahl 1500 (Pharma):</strong>
                      </div>
                      <div class="col">2 Tage Vorlauf</div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <strong>Edelstahl 3000:</strong>
                      </div>
                      <div class="col">1 Tag Vorlauf & 1 Tag Nachlauf</div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <strong>Edelstahl 3000 (Pharma):</strong>
                      </div>
                      <div class="col">2 Tage Vorlauf & 1 Tag Nachlauf</div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <strong>Sonstige Stücklung:</strong>
                      </div>
                      <div class="col">1 Tag Vorlauf & 1 Tag Nachlauf</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <create-reservation-form
                    @reservationCreated="onCreateBooking($event)"
                  ></create-reservation-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingsTable from '@/components/bookings/ReservationsTable';
import CreateReservationForm from '@/components/bookings/CreateReservationForm';
import {
  getReservations as getBookings,
  deleteReservation as deleteBooking,
  getReservationsCount as getBookingsCount,
  updateBooking,
  getCustomers,
  getWeights,
  getAvailableWeights,
} from '@/api';
import { getTime, parseISO } from 'date-fns';
import { auth } from '@/firebase';
import { debounce } from 'lodash';

export default {
  name: 'reservations',
  components: { BookingsTable, CreateReservationForm },
  watch: {
    query: {
      handler: debounce(async function (query) {
        this.requesting = true;
        this.bookings = await getBookings(query);
        this.requesting = false;
      }, 500),
      deep: true,
    },
  },
  data() {
    return {
      page: 1,
      bookingsCount: 0,
      bookings: [],
      requesting: false,
      errMsg: '',
      customers: [],
      filteredCustomers: [],
      weights: [],
      availableWeights: [],
      role: '',
      currentUser: auth.currentUser.email,
      query: {
        name: '',
        customerId: '',
        zipcode: '',
        city: '',
        ticketNr: '',
        createdBy: '',
      },
    };
  },
  async created() {
    this.requesting = true;

    try {
      const [bookingsCount, bookings, weights, customers, idTokenResult] =
        await Promise.all([
          getBookingsCount(),
          getBookings(this.query),
          getWeights(),
          getCustomers(),
          auth.currentUser.getIdTokenResult(),
        ]);

      this.bookingsCount = bookingsCount;
      this.bookings = bookings;
      this.weights = weights;
      this.customers = customers;
      this.role = idTokenResult.claims.role;

      this.filteredCustomers = [...this.customers];

      this.requesting = false;
    } catch (err) {
      console.error(err);

      this.requesting = false;
    }
  },
  methods: {
    async onCreateBooking(v) {
      this.bookingsCount++;
      this.bookings.push(v);

      setTimeout(async () => {
        this.bookings = await getBookings(this.query);
      }, 5000);
    },
    async onStartEndChange({ start, end }) {
      try {
        this.availableWeights = [];

        const startTime = getTime(parseISO(start));
        const endTime = getTime(parseISO(end));

        if (startTime > 0 && endTime > 0) {
          const bookedWeights = await getAvailableWeights({
            start: startTime,
            end: endTime,
          });

          this.weights.forEach((weight) => {
            switch (weight.type) {
              case 'Edelstahl 1500': {
                weight.sortVal = 0;
                break;
              }
              case 'Edelstahl 300 (Gewichtsbox)': {
                weight.sortVal = 1;
                break;
              }
              case 'Edelstahl 200 (Korb)': {
                weight.sortVal = 2;
                break;
              }
              case 'F2-Gewicht': {
                weight.sortVal = 3;
                break;
              }
              case 'Guss 600': {
                weight.sortVal = 4;
                break;
              }
              case 'Guss 500': {
                weight.sortVal = 5;
                break;
              }
              case 'Guss 200': {
                weight.sortVal = 6;
                break;
              }
              case 'Guss 100': {
                weight.sortVal = 7;
                break;
              }
              case 'Volumenprüfkörper': {
                weight.sortVal = 8;
                break;
              }
              case 'RapidCal 4t': {
                weight.sortVal = 9;
                break;
              }
              case 'RapidCal8t': {
                weight.sortVal = 10;
                break;
              }
            }

            const bookedWeight = bookedWeights.find(
              (w) => w.type === weight.type
            );

            let amountToSubtract = 0;

            if (bookedWeight) {
              amountToSubtract = bookedWeight.amount;
            }

            this.availableWeights.push({
              ...weight,
              amountAvailable: weight.amount - amountToSubtract,
              amount: 0,
            });
          });
        }

        this.availableWeights.sort((a, b) => {
          if (a.sortVal < b.sortVal) {
            return -1;
          }

          if (a.sortVal > b.sortVal) {
            return 1;
          }

          return 0;
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onDeleteBooking(id) {
      if (confirm('Sind Sie sicher, dass Sie diese Buchung löschen möchten?')) {
        try {
          this.requesting = true;

          await deleteBooking(id);

          this.bookingsCount--;

          this.bookings = this.bookings.filter((booking) => booking._id !== id);

          this.requesting = false;
        } catch (err) {
          console.error(err);

          this.requesting = false;
        }
      }
    },
    async onCustomerQueryChange(query) {
      if (!query) {
        this.filteredCustomers = [...this.customers];
      } else {
        this.filteredCustomers = this.customers.filter(
          (customer) => customer.customerId === query
        );
      }
    },
    async onCreateReservation(booking) {
      try {
        this.requesting = true;

        await updateBooking(booking._id, {
          isReservation: false,
        });

        this.bookingsCount--;

        this.bookings = this.bookings.filter((b) => b._id !== booking._id);

        this.requesting = false;
      } catch (err) {
        console.error(err);

        this.requesting = false;
      }
    },
  },
};
</script>
